import { ONLINE_RIDER_API } from './config'


export const addRiderToIdle = async (id, location) => {
	return await fetch(`${ONLINE_RIDER_API}/riders/idle`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({ id, location, status: true })
	}).then(response => {
		return response
	}).catch(err => {
		return err
	})
}


export const removeRiderToIdle = async (id) => {
	return await fetch(`${ONLINE_RIDER_API}/riders/idle`, {
		method: 'POST', 
		headers: {
			"Content-Type": "application/json"
		}, 
		body: JSON.stringify({id, status: false })
	}).then(response => {
		return response
	}).catch(err => {
		return err
	})
}