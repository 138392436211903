import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import RoomIcon from "@material-ui/icons/Room"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"

import Divider from "@material-ui/core/Divider"

const useStyles = makeStyles({
  root: {
    margin: "0.4em",
    marginBottom: "1em",
    minWidth: 275,
    marginRight: "auto",
    marginLeft: "auto",
    width: "80%",
    borderRadius: "1em",
    padding: "0.7em",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: "1em",
    fontFamily: "visby",
    margin: "0",
  },
  pos: {
    marginBottom: 12,
  },
  detail: {
    fontFamily: "visby",
    marginTop: "1em",
    marginBottom: "1em",
    display: "flex",
  },
  detail__header: {
    fontWeight: "bold",
    fontSize: "0.9em",
  },
  detail__info: {
    fontSize: "1em",
  },
  bidButton: {
    width: "100%",
    backgroundColor: "rgb(6, 128, 239) ",
    border: 0,
    borderRadius: "0.4em",
    fontSize: "1em",
    padding: "0.4em",
    color: "white",
    fontFamily: "visby",
    fontWeight: "bold",
    "&:disabled": {
      opacity: "0.5",
    },
  },
})

const PendingDeliveryCardView = ({
  details,
  onBidLocation = () => {},
  bidSubmitted,
}) => {
  const classes = useStyles()
  let { pickup, dropoff, payment, pabili } = details

  let isShop = pickup[0].name.includes("Shop:")
    ? "Sparkle Food Delivery"
    : pabili?.items?.length > 0
    ? "Sparkle Pasuyo Delivery"
    : "Sparkle Express Delivery"
  let requiredMoney =
    isShop === "Sparkle Pasuyo Delivery"
      ? `Required amount: ${pabili.amount}`
      : ""

  return (
    <Card className={classes.root}>
      <h4 className={classes.title}>{isShop}</h4>
      {requiredMoney}
      <div className={classes.detail}>
        <div
          style={{
            marginRight: "1em",
          }}
        >
          <RoomIcon size="medium" style={{ marginTop: "40%" }} />
        </div>
        <div style={{ flexGrow: "10" }}>
          <div className={classes.detail__header}>Pickup</div>
          <div className={classes.detail__info}>{pickup[0].address}</div>
        </div>
      </div>

      <div className={classes.detail}>
        <div
          style={{
            marginRight: "1em",
          }}
        >
          <RoomIcon size="medium" style={{ marginTop: "40%" }} />
        </div>
        <div style={{ flexGrow: "10" }}>
          <div className={classes.detail__header}>Dropoff</div>
          <div className={classes.detail__info}>{dropoff[0].address}</div>
        </div>
      </div>
      <div className={classes.detail}>
        <div
          style={{
            marginRight: "1em",
          }}
        >
          <RoomIcon size="medium" style={{ marginTop: "40%" }} />
        </div>
        <div style={{ flexGrow: "10" }}>
          <div className={classes.detail__header}>Delivery Fee</div>
          <div className={classes.detail__info}>{payment[0].deliveryFee}</div>
        </div>
      </div>
      <button
        disabled={bidSubmitted}
        onClick={onBidLocation}
        className={classes.bidButton}
      >
        {bidSubmitted ? "Location Bid successful" : "Gusto ko to"}
      </button>
    </Card>
  )
}

export default PendingDeliveryCardView
