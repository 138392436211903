import React from 'react'

import watchRiderLocation from '../../../custom-hooks/location'

const LocationLogger = () => {
	watchRiderLocation();
	return <></>
}


export default LocationLogger;