import React, { useEffect } from "react"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import { useRecoilValue } from "recoil"
import onlineOfflineState from "../../../recoil-state/onlineOfflineState"

const ConnectionStatusOverlay = () => {
  let status = useRecoilValue(onlineOfflineState)

  return (
    <>
      <Dialog open={!status}>
        <DialogTitle id="alert-dialog-online">Yikes!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You do have poor internet connection as of the moment, please wait
            while we connect you.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ConnectionStatusOverlay
