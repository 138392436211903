import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import PendingDeliveryCard from "./pendingDeliveriCard"

import { useRecoilState, useSetRecoilState } from "recoil"
import deliveryDetailsView from "../../../recoil-state/deliveryDetailsView"

const ListOfPendingDeliveries = ({ deliveries }) => {
  let setView = useSetRecoilState(deliveryDetailsView)
  const settings = {
    dots: true,
    arrows: true,
    accesisibility: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => {
      setView(deliveries[index])
      console.log("changing")
    },
  }
  return (
    <Slider {...settings}>
      {deliveries.map((delivery) => (
        <PendingDeliveryCard key={delivery.orderId} details={delivery} />
      ))}
    </Slider>
  )
}

export default ListOfPendingDeliveries
