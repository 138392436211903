import React from "react"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    margin: "0.4em",
    marginBottom: "1em",
    minWidth: 275,
    marginRight: "auto",
    marginLeft: "auto",
    width: "80%",
    borderRadius: "1em",
    padding: "0.7em",
    height: "10em",
    fontFamily: "visby",
  },
})

const NoAvailableDeliveries = () => {
  let classes = useStyles()
  return (
    <Card className={classes.root}>
      <h3>You do not have available deliveries at the moment.</h3>
    </Card>
  )
}

export default NoAvailableDeliveries
