import React, { useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"

import newDeliveryAlert from "../../../recoil-state/newDeliveryAlert"
import { useRecoilState } from "recoil"

import Alert from "../../../assets/sounds/alert-rider.wav"
import ReactHowler from 'react-howler'

const useAlert = () => {
  const [alert, setAlert] = useRecoilState(newDeliveryAlert)

  return (
    <>
    <ReactHowler src = {Alert} playing = {alert}/>
    <Dialog open={alert}>
      <DialogTitle id="alert-dialog-title">Paalala</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Nagbago ang listahan mo ng mga deliveries!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            setAlert(false)
          }}
        >
          Sige
        </Button>
      </DialogActions>
    </Dialog>
    </>
  )
}

export default useAlert
