import React, { useEffect } from "react"
import Map from "../components/spark-express/map"
import PendingDeliveries from "../components/spark-express/pendingDeliveries"
import DeliveriesToggle from "../components/spark-express/deliveriesToggle"
import DeliveriesAlert from "../components/spark-express/newDeliveryAlert"
import LocationNeededDialog from "../components/spark-express/locationNeededDialog"
import LocationBidDialog from "../components/spark-express/locationBidDialog"

import LocationLogger from "../components/spark-express/locationLogger"
import AssignedDeliveriesReceiver from "../components/spark-express/assignedDeliveriesReceiver"
import AvailableDeliveriesReceiver from "../components/spark-express/availableDeliveriesReceiver"
import ongoingDelivery from "../recoil-state/ongoingDelivery"
import { useRecoilValue } from "recoil"

//processing deliveries component
import DeliveryManagement from "../components/spark-express/deliveryManagement"
import OngoingDeliveryStatus from "../components/spark-express/ongoingDeliveryStatus"
import OngoingDeliveryDetails from "../components/spark-express/ongoingDeliveryDetails"
import DeliveryAssignedAlert from "../components/spark-express/deliveryAssignedAlert"

import LocationEmitter from "../components/spark-express/locationEmitter"

import ConnectionBeacon from "../components/spark-express/connectionBeacon"
import ConnectionStatusOverlay from "../components/spark-express/connectionStatusOverlay"

const Top = () => {
  const deliveryOngoing = useRecoilValue(ongoingDelivery)
  useEffect(() => {
    console.log("TOP COMPONENT")
    console.log(deliveryOngoing)
  }, [deliveryOngoing])
  return (
    <>
      {deliveryOngoing.orderId ? (
        <>
          <OngoingDeliveryStatus />
        </>
      ) : (
        <DeliveriesToggle />
      )}
    </>
  )
}

const Bottom = () => {
  console.log("BOTTOM COMPONENT")
  const deliveryOngoing = useRecoilValue(ongoingDelivery)
  return (
    <>
      {deliveryOngoing?.orderId ? (
        <>
          <DeliveryManagement />
          <OngoingDeliveryDetails />
        </>
      ) : (
        <PendingDeliveries />
      )}
    </>
  )
}

const Hooks = () => {
  console.log("HOOKS")
  const deliveryOngoing = useRecoilValue(ongoingDelivery)

  return (
    <>
      {deliveryOngoing?.orderId ? (
        <>
          <LocationEmitter />
          <AvailableDeliveriesReceiver />
        </>
      ) : (
        <>
          <LocationLogger />
          <AvailableDeliveriesReceiver />
          <AssignedDeliveriesReceiver />
        </>
      )}
    </>
  )
}

const Msc = () => {
  console.log("MSC")
  return (
    <>
      <DeliveriesAlert />
      <LocationNeededDialog />
      <DeliveryAssignedAlert />
      <LocationBidDialog />
    </>
  )
}

const ConnectionStatus = () => {
  return (
    <>
      <ConnectionBeacon />
      <ConnectionStatusOverlay />
    </>
  )
}

const Deliveries = () => {
  console.log("DELIVERIES PAGE")
  //all realtime data will be here

  return (
    <>
      <ConnectionStatus />
      <Top />
      <Hooks />

      <Map />
      <Bottom />

      <Msc />
    </>
  )
}

export default Deliveries
