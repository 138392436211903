import React, { useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import { useRecoilState } from "recoil"

import locationBidDialog from "../../../recoil-state/locationBidDialog"

const LocationBidDialog = () => {
  let [open, toggle] = useRecoilState(locationBidDialog)

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Paalala</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Maghintay lamang po hanggang 1 minuto para sa konpirmasyon. Maraming
            salamat po!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => toggle(false)}>
            Sige
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LocationBidDialog
