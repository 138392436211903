import React, { useEffect, useState } from "react"

import { useSetRecoilState } from "recoil"
import receiveDeliveries from "../../../recoil-state/receiveDeliveries"

import onlineOfflineState from "../../../recoil-state/onlineOfflineState"
import io from "socket.io-client"
import {SOCKET} from './../../../api/spark-express/config'
import { getUser } from "../../../services/auth"
//this file is responsible for connection and disconnection in realtime
const ConnectionBeacon = () => {
  const [socket, setSocket] = useState(null)
  const setReceiveDeliveries = useSetRecoilState(receiveDeliveries)
  const setConnectionStatus = useSetRecoilState(onlineOfflineState)


  useEffect(() => {
    console.log(SOCKET)
    const newSocket = io(SOCKET)
    setSocket(newSocket)
    newSocket.on("connect", () => {
      console.log('connected')
      newSocket.emit("connectRider", getUser().userId)
      setConnectionStatus(true)
    })

    newSocket.on("disconnect", () => {
      console.log('disconnected')
      setReceiveDeliveries(false)
      setConnectionStatus(false)
    })

    newSocket.on("connect_error", () => {
      console.log('connect error')
      setReceiveDeliveries(false)
      setConnectionStatus(false)
    })

    return () => {
      newSocket.close()
    }
  }, [setSocket])

  return <></>
}

export default ConnectionBeacon
