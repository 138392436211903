import React, { useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import locationPermission from "../../../recoil-state/locationPermission"
import { useRecoilValue } from "recoil"

const LocationNeededDialog = () => {
  const permitted = useRecoilValue(locationPermission)
  return (
    <>
      <Dialog open={!permitted}>
        <DialogTitle id="alert-dialog-title">Paalala</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Paki turn-on po yung location permission sa cellphone niyo. I-refresh niyo na lang po ulit kung okay na.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LocationNeededDialog
