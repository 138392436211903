import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ListOfPendingDeliveries from "./ListOfPendingDeliveries"
import NoAvailableDeliveries from "./NoAvailableDeliveries"
import ReceiveDeliveriesOff from "./ReceiveDeliveriesOff"

import { useRecoilValue } from "recoil"
import receiveDeliveries from "../../../recoil-state/receiveDeliveries"
import deliveriesAvailable from "../../../recoil-state/deliveries"

const style = makeStyles((theme) => ({
  body: {
    zIndex: 99,
    position: "fixed",
    top: "70%",
    bottom: "0",
    borderTopLeftRadius: "1em",
    borderTopRightRadius: "1em",
    width: "100%",
    paddingTop: "0.5em",
    overflow: "scroll",
  },
}))

const PendingDeliveries = () => {
  const styles = style()
  let isReceivingDeliveries = useRecoilValue(receiveDeliveries)
  let deliveries = useRecoilValue(deliveriesAvailable)

  return (
    <div className={styles.body}>
      {isReceivingDeliveries ? (
        deliveries.length > 0 ? (
          <ListOfPendingDeliveries deliveries={deliveries} />
        ) : (
          <NoAvailableDeliveries />
        )
      ) : (
        <ReceiveDeliveriesOff />
      )}
    </div>
  )
}
export default PendingDeliveries
