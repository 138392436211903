//this file watches for assigned deliveries


import { useEffect, useState } from "react"
import firebase from "../services/firebase"
import { getUser } from "../services/auth"
import { navigate } from "gatsby"

import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil"
import deliveryDetailsView from "../recoil-state/deliveryDetailsView"
import locationPermission from "../recoil-state/locationPermission"

import ongoingDeliveryDetails from "../recoil-state/ongoingDelivery"

const OngoingDelivery = () => {
  let setOngoingDelivery = useSetRecoilState(ongoingDeliveryDetails)

  let setDeliveryDetailsView = useSetRecoilState(deliveryDetailsView)
  let locationPermitted = useRecoilValue(locationPermission)

  function assignRider(orderId) {
    let ref = firebase.database.ref(`order_status/${orderId}/rider`)

    ref.update({
      name: getUser().name,
      phone: getUser().phone,
      messenger: getUser().messenger,
    })
  }


  //process starts here, it listens to firebase if there is an ongoing delivery assigned to rider
  useEffect(() => {
    let mounted = true
    console.log("location permitted kuno  ")

    if (mounted && locationPermitted) {
      let ongoingDelivery = firebase.database.ref(
        `rider_ongoing_deliveries/${getUser().userId}`
      )
      ongoingDelivery.on("value", async (snapshot) => {
        let ongoingDelivery = snapshot.val()

        if (ongoingDelivery?.orderId) {
          console.log("iaasign step 1 ")
          let order = await firebase.database
            .ref(`order_status/${ongoingDelivery.orderId}`)
            .once("value")

          console.log(order.val())
          if (order.val()?.orderId && locationPermitted) {
            console.log("thi sis now assigning")
            setDeliveryDetailsView(order.val())
            setOngoingDelivery(order.val())
            //update the order status
            assignRider(order.val().orderId)
          } else {
            setOngoingDelivery({})
            setDeliveryDetailsView({})
          }
        } else {
          setOngoingDelivery({})
          setDeliveryDetailsView({})
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [locationPermitted])
}

export default OngoingDelivery
