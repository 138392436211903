import React from 'react'


import watchForAssignedDeliveries from '../../../custom-hooks/ongoingDelivery'


const AssignedDeliveriesReceiver = () => {
	console.log('render: assigned deliveries receiver')
	watchForAssignedDeliveries();
	return <></>
}


export default AssignedDeliveriesReceiver